import styled from "@emotion/styled"
import Axios from "axios"
import React from "react"
import { isPersonalEmail } from "../../shared/utils"
import { FUNCTIONS_ENDPOINT } from "../config"
import { useCookieConsentContext } from "../contexts/cookieConsent"
import colors from "../styles/colors"
import { trackError } from "../utils/segment"
import { identify, trackEvent } from "../utils/trackEvent"
import { SendingButton } from "./button"
import Input from "./input"
import Typography, { typographyComponents } from "./typography"

const Wrapper = styled.div`
  background-color: ${colors.white};
  border-radius: 12px;
  width: 414px;
  height: 600px;

  display: flex;
  justify-content: center;
  padding: 42px 0;
`
const InnerWrapperForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  width: 330px;
`
const Title = styled(Typography)`
  color: ${colors.primary};
  max-width: 200px;
  text-align: center;
`
const InputFields = styled.div``
const SubmitButton = styled(SendingButton)`
  border-radius: 1000px;
  width: 100%;
  height: 90px;
  justify-content: center;
  text-transform: initial;
`

const InputField = styled(Input)`
  border-color: #ccc;
  border-width: 1px;
  width: 100%;
  height: 50px;
  margin-bottom: 8px;

  &:focus {
    border-color: #2f80ed;
  }
`

const ErrorText = styled(typographyComponents.small)`
  color: ${colors.primary};
  margin-bottom: 5px;
  display: block;
`

export const RequestDemoForm = ({ formTitle, from }) => {
  const [firstName, setFirstName] = React.useState("")
  const [lastName, setLastName] = React.useState("")
  const [email, setEmail] = React.useState("")
  const [companyName, setCompanyName] = React.useState("")
  const [title, setTitle] = React.useState("")
  const [isSending, setIsSending] = React.useState(false)
  const [isSuccess, setIsSuccess] = React.useState(false)
  const [isError, setIsError] = React.useState(false)

  const { initialConsent } = useCookieConsentContext()

  const onSubmit = React.useCallback(
    async e => {
      e.preventDefault()
      setIsSending(true)
      identify(email)
      try {
        const response = await Axios.post(
          `${FUNCTIONS_ENDPOINT}/request-demo`,
          {
            email,
            name: `${firstName} ${lastName}`,
            companyName,
            title,
            from,
          }
        )
        if (response.data.status === "success") {
          if (initialConsent.includes("Marketing")) {
            const img = new Image(1, 1)
            img.src =
              "https://px.ads.linkedin.com/collect/?pid=3105452&conversionId=4091204&fmt=gif"
          }
          trackEvent({
            category: "generate_lead",
            label: `Lead from ${from}`,
            siteSection: "request_demo_form",
          })
          setIsSuccess(true)
        } else {
          throw new Error(
            `Demo request failed: ${JSON.stringify(response.data, null, 2)}`
          )
        }
      } catch (e) {
        trackError(e)
        setIsError(true)
      } finally {
        setIsSending(false)
      }
    },
    [companyName, email, firstName, from, initialConsent, lastName, title]
  )

  const isPersonal = isPersonalEmail(email)

  return (
    <Wrapper>
      <InnerWrapperForm onSubmit={onSubmit}>
        <Title variant="h6">{formTitle}</Title>
        <InputFields>
          <InputField
            value={firstName}
            onChange={e => setFirstName(e.target.value)}
            variant="white"
            placeholder="First name"
            required
          />
          <InputField
            value={lastName}
            onChange={e => setLastName(e.target.value)}
            variant="white"
            placeholder="Last name"
            required
          />
          <InputField
            value={email}
            onChange={e => setEmail(e.target.value)}
            variant="white"
            placeholder="Business email"
            required
            type="email"
          />
          {isPersonal && (
            <ErrorText>
              <span aria-hidden>⚠️</span>To request a demo, please use your
              business email address (no Gmail, Hotmail etc).
            </ErrorText>
          )}
          <InputField
            value={companyName}
            onChange={e => setCompanyName(e.target.value)}
            variant="white"
            placeholder="Company Name"
            required
          />
          <InputField
            value={title}
            onChange={e => setTitle(e.target.value)}
            variant="white"
            placeholder="Title"
            required
          />
        </InputFields>
        <SubmitButton
          type="submit"
          isSending={isSending}
          disabled={isSending || isPersonal || isSuccess}
        >
          {!isSuccess
            ? isError
              ? "Something went wrong"
              : "Request a demo"
            : "Thanks! Speak soon."}
        </SubmitButton>
      </InnerWrapperForm>
    </Wrapper>
  )
}
