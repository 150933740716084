import React from "react"
import styled from "@emotion/styled"

import { CardWrapper, CardContentWrapper } from "../card"
import Typography from "../typography"
import colors from "../../styles/colors"
import { RequestDemoForm } from "../RequestDemoForm"
import { DesktopMediaQuery, MobileMediaQuery } from "../../styles/constants"

const Card = styled(CardWrapper)`
  padding-bottom: 0;
  margin-bottom: 0;
  overflow: hidden;
  max-width: initial;
  margin-left: -4px !important;
  margin-right: -4px !important;
  border-radius: 0 !important;
`

const Content = styled(CardContentWrapper)`
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  ${MobileMediaQuery} {
    flex-direction: column;
  }
`

const LeftColumn = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
`
const Title = styled(Typography)`
  ${DesktopMediaQuery} {
    margin-right: 2rem;
    margin-top: 1.3rem;
    margin-bottom: 1.5rem;
  }
  ${MobileMediaQuery} {
    margin-top: 60px;
    margin-bottom: 2rem;
  }
`

const RightColumn = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  position: relative;
  ${MobileMediaQuery} {
    margin-top: 32px;
    width: 100%;
  }
`

const NextGenRequestDemoFormWrapper = styled.div`
  margin-top: 48px;
  display: inline-block;

  ${MobileMediaQuery} {
    & > * {
      width: 100%;
    }
  }
`

const Subtitle = styled(Typography)`
  ${MobileMediaQuery} {
    max-width: 370px;
  }
`
const ZigZagSvg = styled.svg`
  position: absolute;
  top: 24px;
  left: -15%;
  height: 100%;
  color: ${colors.primary};
`

export const CardBook = () => {
  return (
    <Card variant="darkgrey">
      <Content>
        <ZigZagSvg fill={colors.primary} viewBox="0 0 991 743">
          <path d="M353.964 193.255L454.254 225.998L527.722 0.96611L311.26 96.7844L353.964 193.255ZM156.35 798.549L56.0594 765.806L235.532 868.266L156.35 798.549ZM723.857 154L825.603 181.891L644.675 84.2827L723.857 154ZM554.111 773.223L452.365 745.331L405.035 917.989L579.006 875.744L554.111 773.223ZM867 697.245L971.658 710.545L990.971 558.575L842.105 594.724L867 697.245ZM712.938 1072.7C705.593 1130.5 746.495 1183.31 804.296 1190.66C862.098 1198 914.909 1157.1 922.255 1099.3L712.938 1072.7ZM-35.2964 480.938L396.667 289.726L311.26 96.7844L-120.704 287.996L-35.2964 480.938ZM253.673 160.513L56.0594 765.806L256.64 831.291L454.254 225.998L253.673 160.513ZM235.532 868.266L803.038 223.717L644.675 84.2827L77.1681 728.832L235.532 868.266ZM622.11 126.109L452.365 745.331L655.858 801.114L825.603 181.891L622.11 126.109ZM579.006 875.744L891.895 799.765L842.105 594.724L529.216 670.702L579.006 875.744ZM762.342 683.944L712.938 1072.7L922.255 1099.3L971.658 710.545L762.342 683.944Z" />
        </ZigZagSvg>
        <LeftColumn>
          <Title variant="h2" as="h3">
            Get a personal demo of Mavenoid
          </Title>
          <Subtitle variant="bodyLarge" style={{ maxWidth: 620 }}>
            Mavenoid can make your customers happier, increase automation and
            make your agents more efficient.
            <br />
            <br />
            Ready to see how it works in detail? Schedule a demo with us today!
          </Subtitle>
          <NextGenRequestDemoFormWrapper>
            <RequestDemoForm
              from="testdrive"
              formTitle="Schedule a demo to learn more"
            />
          </NextGenRequestDemoFormWrapper>
        </LeftColumn>
        <RightColumn style={{ width: 400, flex: "none" }} />
      </Content>
    </Card>
  )
}
