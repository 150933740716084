import styled from "@emotion/styled"
import { GatsbyImage, GatsbyImageProps } from "gatsby-plugin-image"
import React from "react"
import { ContentfulImageProps as ImageProps } from "../types/contentful"

const SvgContainer = styled.div`
  height: 100%;

  svg {
    height: 100%;
    width: 100%;
  }
`

interface ContentfulImageProps extends Omit<GatsbyImageProps, "image" | "alt"> {
  image: ImageProps
  maxWidth?: number
}

export const ContentfulImage = ({
  image,
  maxWidth,
  ...props
}: ContentfulImageProps): JSX.Element => {
  if (image.svg?.content)
    return (
      <SvgContainer
        dangerouslySetInnerHTML={{ __html: image.svg.content }}
        style={{
          maxWidth: `${
            maxWidth !== null ? maxWidth : image.file.details.image.width
          }px`,
          // width: image.file.details.image.width,
          // height: image.file.details.image.height,
        }}
        {...props}
      />
    )

  return (
    <GatsbyImage
      image={image.gatsbyImageData}
      alt={image.title ?? "image"}
      {...props}
    />
  )
}
