import styled from "@emotion/styled"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import colors from "../../styles/colors"
import { IWidgetProps, WidgetQuote } from "../../types/widget"
import Card, { CardProps } from "../card"
import { ContentfulImage } from "../ContentfulImage"
import { TypographyMarkdown, typographyStyles } from "../typography"

const CardVerticalCenter = styled(Card)`
  padding-top: 6rem;
  padding-bottom: 6rem;
  align-items: center;
  text-align: center;
  border-top: 1px solid ${colors.grey};
  border-bottom: 1px solid ${colors.grey};
`
// This restyles the `svg` elements that have a `fill` color (set to something other than "none") to `currentColor`
// This is done in order to render the SVGs that have a `fill="#333"` to render correctly when
// the widget variant is different
const ContentfulImageWithSVGHack = styled(ContentfulImage)`
  svg[fill]:not([fill="none"]),
  path[fill]:not([fill="none"]) {
    fill: currentColor !important;
  }
`

const QuoteText = styled(TypographyMarkdown)`
  margin: auto;
  margin-top: 1rem;
  max-width: 1024px;

  p {
    ${typographyStyles.h4}
  }
`
const AuthorText = styled(TypographyMarkdown)`
  p {
    ${typographyStyles.bodyLarge}
    margin-bottom: 0;
  }
`

const RoundImage = styled(GatsbyImage)`
  margin-top: 2rem;
  border-radius: 100%;
`
const LOGO_HEIGHT = 23
export const QuoteWidget = ({
  widget,
  variant = "white",
  noMinHeight = true,
  ...props
}: IWidgetProps<WidgetQuote> & CardProps): JSX.Element => (
  <CardVerticalCenter variant={variant} noMinHeight={noMinHeight} {...props}>
    <ContentfulImageWithSVGHack
      image={widget.logo}
      style={{
        height: widget.logoScale ? widget.logoScale * LOGO_HEIGHT : LOGO_HEIGHT,
      }}
    />
    <QuoteText
      dangerouslySetInnerHTML={{
        __html: widget.quote.childMarkdownRemark.html,
      }}
    />
    <RoundImage image={widget.profilePicture.gatsbyImageData} alt="image" />
    <AuthorText
      dangerouslySetInnerHTML={{
        __html: widget.author.childMarkdownRemark.html,
      }}
      style={{ marginTop: "1rem" }}
    />
  </CardVerticalCenter>
)

export const QuoteWidgetFragment = graphql`
  fragment QuoteWidget on ContentfulQuoteWidget {
    __typename
    quote {
      childMarkdownRemark {
        html
      }
    }
    profilePicture {
      gatsbyImageData(placeholder: TRACED_SVG, width: 120)
    }
    logo {
      ...contentfulImageGatsby
    }
    logoScale
    author {
      childMarkdownRemark {
        html
      }
    }
  }
`
